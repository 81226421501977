import './insta.js'
import moment from 'moment';

jQuery( document ).ready(function($) {
  
  
  // Tour
  $.ajax( {
    url: 'https://rest.bandsintown.com/artists/Amanda%20Cook/events?app_id=45PRESS_amanda-cook',
    method: 'GET',
    dataType: 'json',
    xhrFields: {
        withCredentials: false
    },
    error: () => {
    },
    success: data => {
      console.log(data);
      const events = $( '#events' );
      let html = ''; 
      if ( data.length ) {
          for ( let event of data ) {
              const event_location = typeof event.region !== 'undefined' ? event.venue.city + ', ' + event.venue.region : event.venue.city + ', ' + event.venue.country;
              html += '<div class="event" data-bit-id="' + event.id + '">';
              html += '<div class="event-date">' + moment( event.datetime ).format( 'MMMM DD, YYYY' ) + '</div>';
              html += '<div class="event-venue">' + event.venue.name + '</div>';
              html += '<div class="event-location">' + event_location + '</div>';
              html += '<a href="' + event.url + '" target="_blank" class="btn btn-black">Tickets</a>';
              html += '</div>';
            }
          events.html( html );
      } else {
          events.html( 'No upcoming events.' );
      }
    }
  });


});